<template>
  <VueFinalModal
    ref="modalRef"
    v-bind="$attrs"
    :esc-to-close="!sticky"
    :click-to-close="!sticky"
    class="flex justify-center items-end sm:items-center backdrop-blur"
    :content-class="contentClass"
    :content-style="contentStyle"
    :teleport-to="attach"
    :content-transition="{
      'enter-active-class': 'transition duration-200 ease-in-out transform',
      'enter-class': 'translate-y-full',
      'enter-to-class': 'translate-y-0',
      'leave-active-class': 'transition duration-200 ease-in-out transform opacity-0',
      'leave-to-class': 'translate-y-full',
      'leave-class': 'translate-y-0',
    }"
    @before-open="beforeOpen"
    @before-close="beforeClose"
  >
    <div
      class="flex-grow overflow-x-hidden overflow-y-auto bg-gray-900 relative backdrop-filter backdrop-blur-xl"
      :class="{ 'p-4 lg:p-6': !slim, ...customBG }"
    >
      <div class="absolute inset-0 overflow-hidden">
        <div
          class="absolute -left-0 top-10 z-0 transform-gpu blur-3xl sm:left-[calc(50%-18rem)] lg:left-48 lg:top-[calc(50%-30rem)] xl:left-[calc(50%-24rem)] opacity-50"
          aria-hidden="true"
        >
          <div
            class="aspect-[1108/632] w-[69.25rem] bg-gradient-to-r from-[#80caff] to-[#4f46e5] opacity-20"
            style="
              clip-path: polygon(
                73.6% 51.7%,
                91.7% 11.8%,
                100% 46.4%,
                97.4% 82.2%,
                92.5% 84.9%,
                75.7% 64%,
                55.3% 47.5%,
                46.5% 49.4%,
                45% 62.9%,
                50.3% 87.2%,
                21.3% 64.1%,
                0.1% 100%,
                5.4% 51.1%,
                21.4% 63.9%,
                58.9% 0.2%,
                73.6% 51.7%
              );
            "
          ></div>
        </div>
      </div>
      <div class="relative z-10">
        <slot :close="close" />
      </div>
    </div>

    <button
      v-if="showClose"
      class="absolute z-20 top-0 right-0 mt-4 mr-4 rounded-full p-2 h-10 w-10 flex justify-center items-center font-semibold text-gray-300 hover:text-gray-100 hover:border-gray-500 transform-gpu transition-all"
      @click="close"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        class="w-6 h-6"
      >
        <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
      </svg>
    </button>
  </VueFinalModal>
</template>

<script>
import { VueFinalModal, useVfm } from 'vue-final-modal';

export default {
  name: 'GModal',
  components: { VueFinalModal },
  inheritAttrs: false,
  props: {
    full: {
      type: Boolean,
      default: () => false,
    },
    sticky: {
      type: Boolean,
      default: () => false,
    },
    attach: {
      type: [String, Boolean],
      default: () => 'body',
    },
    slim: {
      type: Boolean,
      default: () => false,
    },
    showClose: {
      type: Boolean,
      default: () => true,
    },
    bg: {
      type: String,
      default: () => null,
    },
    maxWidth: {
      type: [String, Number],
      default: () => 500,
    },
  },
  emits: ['close', 'confirm', 'beforeClose'],
  computed: {
    mw() {
      const { maxWidth = 500 } = this;
      return `${maxWidth}`.replace('px', '').trim();
    },
    contentStyle() {
      const { mw = null } = this;
      const opts = {};
      opts.maxWidth = `${mw}px`;
      return opts;
    },
    contentClass() {
      const c = [
        'relative flex flex-col max-h-full sm:mx-4 shadow-xl rounded-b-none sm:rounded-b-xl rounded-xl overflow-hidden bg-gray-900 w-full',
      ];
      const { mw = null } = this;
      if (mw) {
        c.push(`md:max-w-[${mw}px]`);
      }
      if (this.full) {
        c.push('h-full');
      }
      return c.join(' ');
    },
    customBG() {
      const opts = {};
      if (this.bg) {
        opts[this.bg] = true;
      }
      return opts;
    },
  },
  methods: {
    beforeOpen() {
      this.$mitt.emit('modal-state', true);
    },
    beforeClose() {
      this.$emit('beforeClose', true);
      this.$mitt.emit('modal-state', false);
    },
    close() {
      this.$emit('close', true);
      // if not event listener, close it anyway
      if (!this.$attrs.onClose) {
        const vfm = useVfm();
        vfm.closeAll();
      }
    },
  },
};
</script>